import React from 'react'
import TestimonialComponent from './TestimonialComponent'
const Testimonial = () => {
  return (
    <div
      className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16">
      <div className='flex flex-col items-center lg:gap-3'>
        <div className="text-xl md:text-4xl font-bold mt-4 text-center relative inline-block header-underline pb-3 before:hidden sm:before:block">
          What People Say About Us
        </div>
        <center className='lg:w-1/2 mt-2'>At Best One Way Cabs , we make your long-distance travel smooth, affordable, and convenient. Here are some of the most sought-after drop taxi routes that our customers love! Whether you're heading to the airport, a pilgrimage, or a business trip, we've got you covered with reliable one-way rides across Tamil Nadu, Pondicherry, and Bangalore.</center>
      </div>

      <div className='grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-3'>

        <TestimonialComponent name='Priya R 
'  route=' Chennai to Coimbatore' desc='"bestonewaycabs.com made my travel from Chennai to Bangalore so easy and affordable. Loved the transparent pricing!" ' />
        <TestimonialComponent name='Ramesh V
' route='Madurai to Chennai' desc='"Booking was a breeze, and the driver was courteous and professional. Highly recommended for anyone looking for a one-way taxi." ' />
        <TestimonialComponent name='Karthik M
' route='Chennai to Pondicherry' desc='"Great service with no hidden charges! The  pay for only the ride motto is truly followed here." ' />

      </div>
    </div>
  )
}

export default Testimonial