const telegramApi = '6407882613:AAEChk3KeOVJalRIiGRXAqalsfHloaIQJmI'; // Replace with your actual backend URL
const telegramChatId=6773501201;
const netbixTelegramApi = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'; // Replace with your actual backend URL
const netbixTelegramChatId=1113684027;

// const telegramApi = '7775422884:AAFEDubjNvTf3mU_FMs5UFAMoIqj01HSmYM'; // Replace with your actual backend URL
// const telegramChatId = 6130998735;
// const netbixTelegramApi = '7775422884:AAFEDubjNvTf3mU_FMs5UFAMoIqj01HSmYM'; // Replace with your actual backend URL
// const netbixTelegramChatId = 6130998735;
// mobile number
const customerMobileNumber = '7200617575';
const front_url = "https://bestonewaycabs.com/";
export { telegramApi, telegramChatId, netbixTelegramApi, netbixTelegramChatId, customerMobileNumber, front_url };