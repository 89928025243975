import React from 'react'
import bgslider from '../../assets/images/bg_slide.jpg'
import { MdOutlineAirlineSeatReclineExtra } from "react-icons/md";
import { MdLuggage } from "react-icons/md";
import { PiOfficeChairLight } from "react-icons/pi";
import { FaArrowRightLong } from "react-icons/fa6";
import { BiPhoneCall } from "react-icons/bi";

const PricePlanComponents = ({ place, image, oneway, roundtrip, passengers, luggage }) => {
  const divStyle = {
    backgroundImage: `url(${bgslider})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

  };
  return (
    <div className=" py-6 px-4 shadow-lg border  bg-white h-full">
      <div className=' flex flex-col   flex-1 h-full gap-2' >
        <div className='flex justify-center'>
          <img src={image} width='300px' height='300px' />
        </div>
        <div className='flex flex-col items-center mb-4'>
          <p className='text-center font-bold text-2xl'>{place}</p>
          <div className='grid grid-cols-2 gap-5 mt-4'>
            <div >
              <p className='text-black text-opacity-85 text-lg font-bold'>One Way</p>
              <p className='text-black text-opacity-85 text-md'>&#8377;{oneway}.00/km</p>
            </div>
            <div>
              <p className='text-black text-opacity-85 text-lg font-bold'>Round Trip</p>
              <p className='text-black text-opacity-85 text-md'>&#8377;{roundtrip}.00/km</p>
            </div>
          </div>
        </div>
        <hr />
        <div className='flex flex-col px-5 py-3 gap-5'>
          <div className='flex justify-between w-full '>
            <div className='w-50 flex justify-center items-center gap-3'>{<MdOutlineAirlineSeatReclineExtra size={35} className='text-[#3f5965]' />
            } <span className='text-md'>Passengers </span></div>
            <span className='flex-end '>{passengers}</span>
          </div>
          <div className='flex justify-between w-full '>
            <div className='w-50 flex justify-center items-center gap-3'>{<MdLuggage size={35} className='text-[#3f5965]' />
            } <span className='text-md'>luggage </span></div>
            <span className='flex-end '>{luggage}</span>
          </div>
          <div className='flex justify-between w-full '>
            <div className='w-50 flex justify-center items-center gap-3'>{<PiOfficeChairLight size={35} className='text-[#3f5965]' />
            } <span className='text-md'>Driver Charge </span></div>
            <span className='flex-end '>&#8377;400</span>
          </div>
        </div>
        <div className='lg:flex-row flex flex-col gap-2'>
          <a
            href="#"
            className=" rounded-md th-btn hover:bg-secondary-color transition-all ease-linear  py-2"  >
            <span className="flex gap-x-2 items-center  hover:text-black justify-center text-white">
              Book Now <FaArrowRightLong size={17} />
            </span>
          </a>
          <a
            href="tel:+917200617575"
            className=" rounded-md th-btn  hover:bg-secondary-color transition-all ease-linear  py-2"  >
            <span className="flex gap-x-2 items-center justify-center hover:text-black text-white">
              <BiPhoneCall size={17} /> +917200617575
            </span>
          </a>
        </div>
      </div>
      {/* <div className=' flex flex-col   justify-between flex-1 h-full gap-3' >
    <div className=' flex flex-col justify-center items-center '>
      <p className=" font-bold text-center text-2xl">{place}</p>
      <div className='h-44 w-44 rounded-full shadow-2xl bg-[#3f5965] flex justify-center items-center mt-5 outline outline-3 outline-white' style={divStyle}><img src={image} className=" w-[145px] mx-auto" /> </div> 
      </div>
      <div className=" flex gap-x-2 justify-between mt-3 pb-3  flex-1">
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-bold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl ">{oneway}</span>
              </span>
              <span className=" opacity-50 font-semibold">One Way</span>
          </div>
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-semibold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl">{roundtrip}</span>
              </span>
              <span className=" opacity-50 font-semibold">Round Trip</span>
          </div>
      </div>
      <div className=" mt-3">
          <a  href="tel:+917200617575"  className="th-btn-reverse w-full">CHOOSE CAB</a>
      </div>
    </div> */}
    </div>
  )
}

export default PricePlanComponents
