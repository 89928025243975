import React, { useEffect, useRef, useState } from "react";
import selct_car from "../assets/images/selct_car.svg";
import select_car from "../assets/images/selct_car.svg";
import sedan from "../assets/images/sedanBgNone.png";
import sedanbg from "../assets/images/sedan.png";
import etiosbg from "../assets/images/etios.png";
import etios from "../assets/images/etiosbgnone.png"
import suvbg from "../assets/images/suv.png";
import suv from "../assets/images/suvBgNone.png"
import innovabg from "../assets/images/innova.png";
import innova from "../assets/images/InnovaBgNone.png"
import tempobg from "../assets/images/tempo.png";
import tempo from "../assets/images/tempoBgNone.png"
import busbg from "../assets/images/bus.png";
import bus from "../assets/images/busBgNone.png"
import { useForm } from "react-hook-form";
import indiaFlag from "../assets/images/indiaflag.png";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaCalendarAlt } from "react-icons/fa";
import { MdOutlineAccessTime } from "react-icons/md";
import { FaCarSide } from "react-icons/fa6";
import {
  Google,
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
  GoogleApiWrapper,
  DistanceMatrixService,
} from "@react-google-maps/api";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaUser } from "react-icons/fa6";
import { CiUser } from "react-icons/ci";
import { IoMdTime } from "react-icons/io";
import { MdLuggage } from "react-icons/md";
import { Loader } from "@googlemaps/js-api-loader"; //npm install @googlemaps/js-api-loader

import { netbixTelegramChatId, telegramApi, telegramChatId, netbixTelegramApi, customerMobileNumber, front_url } from "../config/config";
const libraries = ["places"];

const OneWay = ({ tripType, fromChange }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [finalMobileNumber, setFinalNumber] = useState();
  const [driverBeta, setDriverBeta] = useState(400);
  const [carList, setCarList] = useState(false);
  // const[carImge,setCarImage] =useState();
  const [listCars, setListCars] = useState({ car_amount: 0 });
  const [carReq, setCarReq] = useState(false);
  const [distanceResult, setDistanceResult] = useState({
    org_distance: null,
    org_duration: null,
  });

  const [carImages, setCarImages] = useState(null);

  const [google, setGoogle] = useState(null);
  const [whatsaappMsg, setWhatsaappMsg] = useState("");

  const [popup, setPopup] = useState(false);

  const [popupDis, setPopupDis] = useState(0);
  const [popupDur, setPopupDur] = useState("");
  const [popupCar, setPopupCar] = useState("");
  const [popupRate, setPopupRate] = useState(0);
  const [totRate, setTotalRate] = useState(0);
  function openCars() {
    setCarList(!carList);
  }
  const searchBox = useRef(null);

  const formRef = useRef(null);

  function sendWhatsapp() {
    document.body.classList.remove("dis_scroll");
    setPopup(false);
    window.open(`https://wa.me/+91${customerMobileNumber}?text=${whatsaappMsg}`);
  }

  const handlePlacesChanged = () => {
    const places = searchBox.current.getPlaces();
    console.log(places);
  };

  // const { isLoaded, loadError } = useLoadScript({
  //     googleMapsApiKey: 'AIzaSyCYfVYTCGKRhBNN5v6uIqb3C477IWcVcCU',
  //     libraries,
  // });

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    clearErrors
  } = useForm();

  useEffect(() => {
    if (window.google) {
      setGoogle(window.google);
    }
  }, []);

  const inputRef = useRef(null);
  const inputRefDrop = useRef(null);
  const [loadingButton, setBoadingButton] = useState(true);


  useEffect(() => {
    // Load Google Maps API
    const loader = new Loader({
      apiKey: "AIzaSyCyxa0d5VJiM4MCBrdLrSCa_CRA0TTYhpA", // Replace with your API key
      libraries: ["places"], // Include the places library
    });

    loader.load().then(() => {
      const southIndiaBounds = {
        north: 13.0, // Adjusted northern boundary of South India
        south: 8.0,  // Southern boundary (Kanyakumari)
        west: 73.0,  // Western boundary (Kerala and Karnataka)
        east: 80.5,  // Eastern boundary (Andhra Pradesh and Tamil Nadu)
      };

      // Initialize Autocomplete
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        bounds: southIndiaBounds,         // Set South India bounds
        strictBounds: true,               // Enforce bounds
        componentRestrictions: { country: "IN" }, // Restrict to India
      });

      const autocompleteDrop = new window.google.maps.places.Autocomplete(inputRefDrop.current, {
        bounds: southIndiaBounds,         // Set South India bounds
        strictBounds: true,               // Enforce bounds
        componentRestrictions: { country: "IN" }, // Restrict to India
      });

      autocompleteDrop.addListener("place_changed", () => {

        const place = autocompleteDrop.getPlace();

        if (place && place.geometry) {
          setValue('drop_add', place);
          clearErrors('drop_add', place);
          console.log("Selected place:", place);

        } else {
          console.log("No details available for this selection.");
        }
      });

      // Event listener for when a place is selected
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.geometry) {
          console.log("Selected place:", place);
          setValue('pick_up_add', place);
          clearErrors('pick_up_add', place);


          console.log("Selected place geometry:", place.geometry);


        } else {
          console.log("No details available for this selection.");
        }
      });
    });
  }, []);

  const onSubmit = async (data) => {
    var day = selectedDate.getDate();
    console.log(day);
    var month = selectedDate.getMonth() + 1;
    console.log(month);

    var year = selectedDate.getFullYear();
    console.log(year);
    var formattedDate = day + '/' + month + '/' + year;
    console.log(formattedDate);

    if (listCars.car_amount === 0) {
      setCarReq(true);
      return;
    } else {
      setCarReq(false);
    }
    if (
      listCars.car_amount !== "0" &&
      data.drop_add !== "" &&
      data.full_name !== "" &&
      data.pick_up_add !== "" &&
      selectedDate !== "" &&
      data.pickup_time !== ""
    ) {
      setBoadingButton(true);
      if (data.drop_add === data.pick_up_add) {
        return alert("Please select Different Places");
      }
      if (!google) {
        alert("Google Maps API is not loaded yet.");
        return;
      }
      setPopupCar(listCars.text);

      // Function to ensure a valid address (string) or LatLng
      const getLatLng = (address) => {
        return new Promise((resolve, reject) => {
          if (address instanceof google.maps.LatLng) {
            resolve(address);
          } else if (typeof address === "string") {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
              if (status === "OK" && results[0]) {
                resolve(results[0].geometry.location);
              } else {
                reject(new Error(`Geocode failed for address: ${address}, reason: ${status}`));
              }
            });
          } else {
            reject(new Error("Invalid address or LatLng object"));
          }
        });
      };

      try {
        const pickUpAddress = typeof data.pick_up_add === "string" ? data.pick_up_add : data.pick_up_add?.formatted_address || "";
        const dropOffAddress = typeof data.drop_add === "string" ? data.drop_add : data.drop_add?.formatted_address || "";

        // Only proceed if both addresses are valid
        if (!pickUpAddress || !dropOffAddress) {
          alert("Invalid address provided. Please check the addresses.");
          setBoadingButton(false);
          return;
        }

        // Get LatLng for pick-up and drop-off locations
        const pickUpLocation = await getLatLng(pickUpAddress);
        const dropOffLocation = await getLatLng(dropOffAddress);

        const distanceMatrixService = new google.maps.DistanceMatrixService();
        const distanceMatrixOptions = {
          origins: [pickUpLocation],
          destinations: [dropOffLocation],
          travelMode: "DRIVING",
        };

        await distanceMatrixService.getDistanceMatrix(
          distanceMatrixOptions,
          (response, status) => {
            if (
              status === "OK" &&
              response.destinationAddresses[0].length !== 0 &&
              response.originAddresses[0].length !== 0
            ) {
              if (
                response.rows[0].elements[0].distance.text ||
                response.rows[0].elements[0].duration.text
              ) {
                var org_distance = response.rows[0].elements[0].distance.text;
                var org_duration = response.rows[0].elements[0].duration.text;
                setPopupDur(org_duration);
                setPopupDis(org_distance);
                setDistanceResult({ org_distance, org_duration });

                if (org_distance != null && org_duration != null) {
                  var one_way_rate =
                    parseInt(org_distance) * parseInt(listCars.car_amount);
                  setTotalRate(
                    parseInt(org_distance) * parseInt(listCars.car_amount)
                  );
                  if (parseInt(org_distance) <= 130) {
                    one_way_rate = 130 * parseInt(listCars.car_amount);
                    setTotalRate(130 * parseInt(listCars.car_amount));
                  }


                  setPopupRate(parseInt(one_way_rate) + driverBeta);

                  const formattedPickUp = pickUpAddress || (data.pick_up_add?.formatted_address || "Unknown Location");
                  const formattedDropOff = dropOffAddress || (data.drop_add?.formatted_address || "Unknown Location");

                  setWhatsaappMsg(
                    "Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " +
                    data.full_name +
                    " ,%0A%0AMobile Number  : +91" +
                    data.mob_number +
                    " ,%0A%0APickup Location  : " +
                    formattedPickUp +
                    " ,%0A%0ADrop Location : " +
                    formattedDropOff +
                    ",%0A%0ATrip Type: One Way ,%0A%0APickup Date/Time: " +
                    formattedDate +
                    " " +
                    // data.pickup_time +
                    // ",%0A%0ATotal KM : " +
                    org_distance +
                    " ,%0A%0ADuration : " +
                    org_duration +
                    " ,%0A%0ARate Per KM: " +
                    listCars.car_amount +
                    " ₹,%0A%0ADriver Beta : " +
                    driverBeta +

                    "  ₹,%0A%0ATotal Trip Fare :" +
                    (parseInt(one_way_rate) + driverBeta) +
                    ` ₹ ,%0A%0AToll, Parking, State Permit Extra %0A%0AFor any questions please contact +91${customerMobileNumber} %0A%0A` +
                    front_url
                  );
                  var message =
                    "Website Enquiry\n******* *******\n\nYour Booking Details:\n\nName : " +
                    data.full_name +
                    " ,\n\nMobile Number  : +91" +
                    data.mob_number +
                    " ,\n\nPickup Location  : " +
                    formattedPickUp +
                    " ,\n\nDrop Location : " +
                    formattedDropOff +
                    ",\n\nTrip Type: One Way ,\n\nPickup Date/Time: " +
                    formattedDate +
                    " " +
                    data.pickup_time +
                    ",\n\nTotal KM : " +
                    org_distance +
                    " ,\n\nDuration : " +
                    org_duration +
                    " ,\n\nRate Per KM: " +
                    listCars.car_amount +
                    " ₹,\n\nDriver Beta : " +
                    driverBeta +

                    "  ₹,\n\nTotal Trip Fare :" +
                    (parseInt(one_way_rate) + driverBeta) +
                    ` ₹ ,\n\nToll, Parking, State Permit Extra \n\nFor any questions please contact +91${customerMobileNumber} \n\n` +
                    front_url;
                  try {
                    const response = axios.post(
                      `https://api.telegram.org/bot${telegramApi}/sendMessage`,
                      {
                        chat_id: telegramChatId,
                        text: message,
                      }
                    );
                    if (response) {
                      setListCars({
                        text: "",
                        car_amount: 0,
                      });
                      setBoadingButton(false);
                      formRef.current.reset();
                      document.body.classList.add("dis_scroll");
                      setPopup(true);
                      setSelectedDate(null);

                    }
                  } catch (error) {
                    setBoadingButton(false);
                    alert("Please try again");
                    console.error("Error sending Telegram message:", error);
                    // Handle the error
                  }
                  try {
                    axios.post(
                      `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
                      {
                        chat_id: netbixTelegramChatId,
                        text: message,
                      }

                    );
                    // if (response) {
                    //   setListCars({
                    //     text: "",
                    //     imageSrc: "",
                    //     car_amount: 0,
                    //   });
                    //   setBoadingButton(false);
                    //   formRef.current.reset();
                    //   document.body.classList.add("dis_scroll");
                    //   setPopup(true);
                    //   setSelectedDate(null);

                    // }

                  } catch (error) {
                    console.error("Error sending Telegram message:", error);
                    // Handle the error
                  }
                } else {
                  alert("Please Try again!");
                }
              }
            } else {
              console.error("Error:", status);
            }
          }
        );
      } catch (error) {
        setBoadingButton(false);
        alert("Error in geocoding addresses.");
        console.error("Error geocoding addresses:", error);
      }
    }
  };
  function popUpClose() {
    document.body.classList.remove("dis_scroll");
    setPopup(false);
  }
  const selectCar = (text, car_amount, carDriverBeta) => {
    setListCars({
      text,
      car_amount,
    });
    setDriverBeta(carDriverBeta);
    setCarList(false);
    setCarImages(text);
    // setCarImages((prevImages) => ({
    //   ...prevImages,
    //   [text]: prevImages[text] === defaultImage ? altImage : defaultImage,
    // }));

  };


  return (
    <div className="mt-6 lg:px-12">
      < form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-col-1 lg:grid-cols-2 ">
          <div className="p-3">
            <input
              type="text"
              className={`w-full border  py-3 px-4 focus:outline-0 ${errors.full_name && "border border-red-500"
                }`}
              placeholder="Enter your Name"
              name="full_name"
              {...register("full_name", { required: true })}
            />
          </div>
          <div className="p-3">
            <div className=" relative">
              <span
                className={`top-1/2 left-0 h-full px-2 border text-[16px] absolute -translate-y-1/2 flex gap-x-1 items-center ${errors.mob_number && "border border-red-500"
                  }`}
              >
                <span>
                  <img src={indiaFlag} className="w-6" />
                </span>
                <span className="">+91</span>
              </span>
              <input
                type="int"
                className={`w-full border  py-3 px-4 focus:outline-0 pl-20 ${errors.mob_number && "border border-red-500"
                  }`}
                {...register("mob_number",
                  {
                    required: "Mobile Number is required", minLength: { value: 10, message: "Requied 10 digit" },
                    maxLength: { value: 10, message: "Requied only 10 digit" }
                  })}
                placeholder="Mobile number"
                name="mob_number"
              />
            </div>
            {errors.mob_number ?
              <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Mobile Number Should Be 10</span> : ''}

          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          {/* pick_up_add */}
          <div className="p-3 ">
            <input
              type="text"
              ref={inputRef}
              name='pick_up_add'
              placeholder="Enter pick-up location"
              // className="form-control" 
              className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                errors.pick_up_add &&
                " border-red-400 border-t-0"
                } ${errors.drop_add && !errors.pick_up_add && " border-red-400 "
                }`} />
            {errors.pick_up_add && (
              <p className="errorMes">{errors.pick_up_add.message}</p>
            )}

            {/* Hidden Input for Form Data */}
            <input
              type="hidden"
              placeholder="Enter pick-up location"
              className="form-control"
              ref={inputRef}
              {...register("pick_up_add", { required: "Field is required" })}
            />

          </div>
          {/* drop_add */}
          <div className="p-3 ">
            <input
              type="text"
              ref={inputRefDrop}
              name='drop_add'
              placeholder="Enter Drop-off Location"
              className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                errors.drop_add &&
                " border-red-400 border-t-0"
                } ${errors.drop_add && !errors.drop_add && " border-red-400 "
                }`}
            />
            {errors.drop_add && (
              <p className="errorMes">{errors.drop_add.message}</p>
            )}
            <input
              type="hidden"
              placeholder="Enter Drop-off Location"
              className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                errors.drop_add &&
                " border-red-400 border-t-0"
                } ${errors.drop_add && !errors.drop_add && " border-red-400 "
                }`}
              ref={inputRefDrop}
              {...register("drop_add", { required: "Field is required" })}
            />
          </div>
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-2 mt-3 ">
          <div className="p-3 ">
            <div>
              <label className="px-2 mb-4 text-lg font-bold">Pickup Date</label>
              <div className="location-input mt-2">

                <FaCalendarAlt className="icon" size={23} />
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  className={`w-full py-3 px-4 focus:outline-0  ${!selectedDate &&
                    errors.pickup_dates &&
                    "border border-red-500"
                    }`}
                  placeholderText="Pick up Date"
                  name="pickup_date"
                  inputMode="none"
                  onFocus={(e) => e.target.blur()}
                />
              </div></div>

            <input
              type="hidden"
              value={selectedDate ? selectedDate : ' '}
              name="pickup_dates"
              {...register("pickup_dates", { required: true })}
            />
          </div>

          <div className="p-3">
            <div>
              <label className="px-2 mb-4 text-lg font-bold">Pickup Time</label>
              <div className="location-input mt-2">

                <MdOutlineAccessTime className="icon" size={23} />
                <select
                  className={`w-full py-3 px-2 focus:outline-0   ${errors.pickup_time && " border border-red-500"
                    }`}
                  id="pickup_time"
                  name="pickup_time"
                  {...register("pickup_time", { required: true })}
                >
                  <option value="">Pickup Time</option>

                  <option value="12:00 AM">12:00 AM</option>

                  <option value="12:30 AM">12:30 AM</option>

                  <option value="01:00 AM">01:00 AM</option>

                  <option value="01:30 AM">01:30 AM</option>

                  <option value="02:00 AM">02:00 AM</option>

                  <option value="02:30 AM">02:30 AM</option>

                  <option value="03:00 AM">03:00 AM</option>

                  <option value="03:30 AM">03:30 AM</option>

                  <option value="04:00 AM">04:00 AM</option>

                  <option value="04:30 AM">04:30 AM</option>

                  <option value="05:00 AM">05:00 AM</option>

                  <option value="05:30 AM">05:30 AM</option>

                  <option value="06:00 AM">06:00 AM</option>

                  <option value="06:30 AM">06:30 AM</option>

                  <option value="07:00 AM">07:00 AM</option>

                  <option value="07:30 AM">07:30 AM</option>

                  <option value="08:00 AM">08:00 AM</option>

                  <option value="08:30 AM">08:30 AM</option>

                  <option value="09:00 AM">09:00 AM</option>

                  <option value="09:30 AM">09:30 AM</option>

                  <option value="10:00 AM">10:00 AM</option>

                  <option value="10:30 AM">10:30 AM</option>

                  <option value="11:00 AM">11:00 AM</option>

                  <option value="11:30 AM">11:30 AM</option>

                  <option value="12:00 PM">12:00 PM</option>

                  <option value="12:30 PM">12:30 PM</option>

                  <option value="01:00 PM">01:00 PM</option>

                  <option value="01:30 PM">01:30 PM</option>

                  <option value="02:00 PM">02:00 PM</option>

                  <option value="02:30 PM">02:30 PM</option>

                  <option value="03:00 PM">03:00 PM</option>

                  <option value="03:30 PM">03:30 PM</option>

                  <option value="04:00 PM">04:00 PM</option>

                  <option value="04:30 PM">04:30 PM</option>

                  <option value="05:00 PM">05:00 PM</option>

                  <option value="05:30 PM">05:30 PM</option>

                  <option value="06:00 PM">06:00 PM</option>

                  <option value="06:30 PM">06:30 PM</option>

                  <option value="07:00 PM">07:00 PM</option>

                  <option value="07:30 PM">07:30 PM</option>

                  <option value="08:00 PM">08:00 PM</option>

                  <option value="08:30 PM">08:30 PM</option>

                  <option value="09:00 PM">09:00 PM</option>

                  <option value="09:30 PM">09:30 PM</option>

                  <option value="10:00 PM">10:00 PM</option>

                  <option value="10:30 PM">10:30 PM</option>

                  <option value="11:00 PM">11:00 PM</option>

                  <option value="11:30 PM">11:30 PM</option>
                </select>
              </div>
            </div>
          </div>
        </div>


        <div className="p-3 ">
          <div className="text-center font-bold text-xl flex gap-2 justify-center items-center">
            <FaCarSide /> Select Choose Vehicle</div>
          <div className={`flex gap-5 flex-wrap mt-3 justify-center `}>

            <div className={`flex flex-col w-[20%] p-2 border rounded-md 
              }`}
            >
              <span className="text-center text-[10px] md:text-[16px]">14₹ /km</span>

              <img
                src={` ${carImages === 'SEDAN' ? sedanbg : sedan}`}
                alt="sedan"
                className="w-full cursor-pointer"
                onClick={() => selectCar("SEDAN", 14, 400)}

              />
              <span className="text-center text-[10px] md:text-[16px]">SEDAN</span>
            </div>
            <div className={`flex flex-col w-[20%] p-2 border rounded-md 
              `}

            >
              <span className="text-center text-[10px] md:text-[16px] ">14₹ /km</span>
              <img
                src={`${carImages === 'ETIOS' ? etiosbg : etios} `}
                alt="etios"
                className="w-full cursor-pointer"

                onClick={() => selectCar("ETIOS", 14, 400)}

              />
              <span className="text-center text-[10px] md:text-[16px]">ETIOS</span>
            </div>
            <div className={`flex flex-col w-[20%] p-2 border rounded-md 
              }`}
            >
              <span className="text-center text-[10px] md:text-[16px] ">19₹ /km</span>
              <img
                src={` ${carImages === 'SUV' ? suvbg : suv} `}
                alt="suv"
                className="w-full cursor-pointer"
                onClick={() => selectCar("SUV", 19, 400)}
              />
              <span className="text-center text-[10px] md:text-[16px]">SUV</span>
            </div>
            <div className={`flex flex-col w-[20%] p-2 border rounded-md 
              }`}
            >
              <span className="text-center text-[10px] md:text-[16px] ">20₹ /km</span>
              <img
                src={`${carImages === 'INNOVA' ? innovabg : innova} `}
                alt="innova"
                className="w-full cursor-pointer"
                onClick={() => selectCar("INNOVA", 20, 400)}
              />
              <span className="text-center text-[10px] md:text-[16px]">INNOVA</span>
            </div>

            {/* <div className={ `flex flex-col w-[20%] p-2 border rounded-md 
              }`}
            >
              <span className="text-center text-[10px] md:text-[16px] ">24₹ /km</span>
              <img
                src={`${carImages === 'TEMPO' ? tempobg : tempo} `}
                alt="tempo"
                className="w-full cursor-pointer"
                onClick={() => selectCar("TEMPO", 24, 400)}
              />
              <span className="text-center text-[10px] md:text-[16px]">TEMPO</span>
            </div> */}
            {/* <div className={ `flex flex-col w-[20%] p-2 border rounded-md 
              }`}
            >
              <span className="text-center text-[10px] md:text-[16px] ">35₹ /km</span>
              <img
                src={`${carImages === 'BUS' ? busbg : bus} `}
                alt="bus"
                className="w-full cursor-pointer"
                onClick={() => selectCar("BUS", 35, 400)}
              />
              <span className="text-center text-[10px] md:text-[16px]">BUS</span>
            </div> */}
          </div>
          {carReq ? (
            <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
              Car is required.
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="text-center mt-5">
          <button className="mt-4 py-3 px-6 th-btn uppercase">Get Estimation</button>
        </div>
      </form>
      {popup ? (
        <div className="fixed top-0 left-0  h-full w-full z-[100]">
          <div className="w-[90%] md:w-3/5 min-h-[200px] bg-gray-400 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute p-5">
            <span
              className="fixed top-2 right-2 cursor-pointer"
              onClick={popUpClose}
            >
              <AiOutlineCloseCircle className="text-[20px]" />
            </span>
            {/* <h2 className="text-[30px]">
            Trip Estimation{" "}
            <span className="primary-color pop-shadow"> Rs.{popupRate}</span>
          </h2> */}
            <div>
              <div className="grid grid-cols-2 py-3 mt-4 ">
                <div className="pl-2 border-b-2 border-t-2 border-l-2 py-3">
                  Total Distance :
                </div>
                <div className="pr-2 border-b-2  border-t-2 border-r-2 border-l-2 px-2 py-3">
                  {popupDis} km
                </div>
                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Total Duration
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                  {popupDur}
                </div>
                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Selected Car Type
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                  {popupCar}
                </div>

                <div className="pl-2 border-b-2 border-l-2 py-3">
                  Total Amount :
                </div>
                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3 font-bold text-xl">
                  Rs. {popupRate} <span class='text-sm font-normal'> ( Driver Beta Included ) </span>
                </div>

              </div>
              <div className="mt-4 text-center">
                <div className="text-md mt-2 font-medium" >One Way Minimum 130 Kms Coverage. </div>
                <div className=" text-md mt-2 font-medium" >Round Trip Minimum 250 Kms Coverage. </div>
                <div className="text-md mt-2 font-medium" >Driver Beta : {driverBeta}</div>

                <div className="text-center mt-2 text-semibold text-lg leading-6">Toll, Parking, Hill Station, State Permit Extra *</div>

              </div>
              <div className="mt-4 text-center">
                <button
                  onClick={sendWhatsapp}
                  className="th-btn py-3 text-white px-6 rounded-md"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default OneWay;
