import React from 'react'
import { Link } from 'react-router-dom';
import bg from "../../assets/images/servbg.png";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
// import logo from '../../assets/images/onewaytaxis_logo.jpg';
import logo from '../../assets/images/BestOneWayLogo.png';


const Footer = () => {
    return (
        <div className="serv_parent  pt-5 sm:pt-10 md:pt-20 lg:pt-28 mt-10" style={{ backgroundImage: `url(${bg})` }}>
            <div className=' container mx-auto px-4 md:px-8'>
                <div className='mt-16'>
                    {/* <div className='flex justify-center mb-4'>
                        <img src={logo} />
                    </div> */}
                    <div className=' flex gap-x-2 md:gap-x-12 justify-center flex-wrap lg:flex-nowrap gap-y-4 '>
                        <div className=' w-full md:w-full lg:w-[25%] text-[#BDBDBD]'>
                            <p>Best One Way Cabs is  providing reliable and convenient one-way taxi services throughout Tamil Nadu, Pondicherry, Bangalore, Hyderabad, and Kochi. Experience the convenience and reliability of our services. Contact us today!</p>
                        </div>
                        <div className=' w-full md:w-full lg:w-[25%] text-[#BDBDBD]' id='contact'>
                            <h3 className=' font-semibold text-xl md:text-2xl text-white'>Contact Us</h3>
                            <ul className=' mt-5 flex flex-col gap-y-3 md:gap-y-6'>
                                <li className=' flex gap-x-2 items-center'><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaLocationDot /></span><span>Puducherry</span></li>
                                <li><a href="tel:+917200617575" className=' flex gap-x-2 items-center w-full group  '><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaPhone /></span><span className='group-hover:text-[#ffc503]'>7200617575</span></a></li>
                                <li className=' flex gap-x-2 items-center'><a href='bestonewaycabs.com@gmail.com' className=' flex gap-x-2 items-center w-full group '><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><IoMail /></span><span className='group-hover:text-[#ffc503]'>bestonewaycabs.com@gmail.com</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' w-full md:w-full  text-[#BDBDBD] px-36 lg:px-36 mt-8 justify-center'>
                <h3 className=' font-semibold text-xl md:text-2xl text-white text-center'>One Way Taxi Cities</h3>
                <ul className=' mt-5 grid gap-x-2 gap-y-3  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center'>
                    <ul className=' flex flex-col gap-y-3'>

                        <Link className='primary-color hover:text-[#fff] transition-all'>Chennai</Link>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Coimbatore</Link>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Bangalore</Link>


                    </ul>
                    <ul className=' flex flex-col gap-y-3'>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Kochi</Link>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Madurai</Link>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Trichy</Link>


                    </ul>
                    <ul className=' flex flex-col gap-y-3'>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Salem</Link>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Tirunelveli</Link>
                        <Link className='primary-color hover:text-[#fff] transition-all'>KanyaKumari</Link>



                    </ul>
                    <ul className=' flex flex-col gap-y-3'>



                        <Link className='primary-color hover:text-[#fff] transition-all'>Thiruvanathapuram</Link>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Rameshwaram</Link>
                        <Link className='primary-color hover:text-[#fff] transition-all'>Thenkasi</Link>



                    </ul>

                </ul>

            </div>
            <div className=' bg-black mt-3 sm:mt-5 md:mt-8 px-4 py-4 sm:py-7'>
                <ul className=' flex flex-col sm:flex-row justify-center gap-x-4 items-center'>
                    <li><Link to="/terms-and-conditions" className=' block primary-color hover:text-white transition-all'>Terms & Conditions</Link></li>
                    <li><Link to="/privacy-policy" className=' block primary-color hover:text-white transition-all'>Privacy Policy</Link></li>
                </ul>
            </div>
            <div className='py-4 sm:py-7 text-white bg-[#2a2f3c] text-center px-4'>
                Copyright @ 2024 <Link className='primary-color hover:text-[#fff] transition-all'>Best One Way Cabs </Link>. All Rights Reserved. Developed By <a href='https://netbixzie.in/' target='_blank' className='primary-color hover:text-[#fff] transition-all'> Netbix Technologies</a>
            </div>
        </div>
    )
}

export default Footer